// import React, {useState} from "react";
// import "./NavItem.css"
// import {ICON_TYPE} from "./TYPE";
// import Icon from "./Icon";
// import {NavLink} from "react-router-dom";
// import {localClear} from "../util/localStorage";
//
//
// interface NavItemProps {
//   NavBoxWidth?: string;       // NavItem 박스 사이즈 지정(넓이)
//   NavBoxHeight?: string;      // NavItem 박스 사이즈 지정(높이)
//   iconType?: ICON_TYPE;       // NavItem ICON 타입 지정(TEXT)
//   iconSize?: string;          // 아이콘 사이즈 지정
//   value?: string;             // NavItem 텍스트
//   to?: string;                // NavItem 클릭시 이동할 경로
//   fold?: boolean;             // Nav 접기 여부
//   depth?: number;           // NavItem depth 여부
//   onClick ?: (isExpanded: boolean) => void;
// }
//
// function NavItem(props: NavItemProps) {
//   const [customNavStyle] = useState<React.CSSProperties>({
//     width: props.NavBoxWidth ?? '100%',
//     height: props.NavBoxHeight ?? '100%',
//     paddingLeft: `${10 + 20 * (props.depth || 0)}px`, // 동적인 padding 설정
//   })
//
//   const [customIconStyle] = useState<React.CSSProperties>({
//     width: props.iconSize ?? '20px',
//     height: props.iconSize ?? '20px',
//   })
//
//   // 확장 상태를 추적하기 위한 상태 추가
//   const [isNavExpanded] = useState(false);
//
//   const handleClick = () => {
//     localClear();
//     if (props.onClick) {
//       props.onClick(isNavExpanded);
//     }
//   };
//
//
//   return (
//     <>
//       <div className={`nav-item ${isNavExpanded ? "selected" : ""}
//       `}
//       >
//         <NavLink
//           onClick={handleClick}
//           className={({isActive}) => (isActive ? "nav-item__active" : "nav-item__deactive")}
//           style={customNavStyle}
//           to={`${props.to}`}>
//           <div className="nav-item__icon-box" style={customIconStyle}>
//             {props.iconType && <Icon className="nav-item__icon" iconType={props.iconType} size={props.iconSize}></Icon>}
//           </div>
//           {!props.fold && <div className="nav-item__contents-box">{props.value}</div>}
//         </NavLink>
//       </div>
//     </>
//   )
// }
//
// export default NavItem;


import React, {useState} from "react";
import "./NavItem.css"
import {ICON_TYPE} from "./TYPE";
import Icon from "./Icon";
import {NavLink} from "react-router-dom";
import {localClear} from "../util/localStorage";


interface NavItemProps {
  NavBoxWidth?: string;       // NavItem 박스 사이즈 지정(넓이)
  NavBoxHeight?: string;      // NavItem 박스 사이즈 지정(높이)
  iconType?: ICON_TYPE;       // NavItem ICON 타입 지정(TEXT)
  iconSize?: string;          // 아이콘 사이즈 지정
  value?: string;             // NavItem 텍스트
  to?: string;                // NavItem 클릭시 이동할 경로
  fold?: boolean;             // Nav 접기 여부
  depth?: number;           // NavItem depth 여부
  onClick ?: (isExpanded: boolean) => void;

  isSelect?: boolean;
}

function NavItem(props: NavItemProps) {
  const [customNavStyle] = useState<React.CSSProperties>({
    width: props.NavBoxWidth ?? '100%',
    height: props.NavBoxHeight ?? '100%',
    paddingLeft: `${10 + 20 * (props.depth || 0)}px`, // 동적인 padding 설정
  })

  const [customIconStyle] = useState<React.CSSProperties>({
    width: props.iconSize ?? '20px',
    height: props.iconSize ?? '20px',
  })

  // 확장 상태를 추적하기 위한 상태 추가
  const [isNavExpanded] = useState(false);

  const handleClick = () => {
    localClear();
    if (props.onClick) {
      props.onClick(isNavExpanded);
    }
  };


  return (
    <>
      <div className={`nav-item ${isNavExpanded ? "selected" : ""}`}>
        <NavLink
          onClick={handleClick}
          className={({isActive}) => (props.isSelect || isActive ? "nav-item__active" : "nav-item__deactive")}
          style={customNavStyle}
          to={`${props.to}`}>
          <div className="nav-item__icon-box" style={customIconStyle}>
            {props.iconType && <Icon className="nav-item__icon" iconType={props.iconType} size={props.iconSize}></Icon>}
          </div>
          {!props.fold && <div className="nav-item__contents-box">{props.value}</div>}
        </NavLink>
      </div>
    </>
  )
}

export default NavItem;