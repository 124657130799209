import ImgBox from "./ImgBox";
import React, {useMemo} from "react";
import "./Logo.css";
import {Link} from "react-router-dom";

interface LogoProps {
  className?: string;
  to?: string | '/';
  value?: string;
  imgSrc?: string;
  imgWidth?: string;
  imgHeight?: string;

  onClick ?: (isExpanded: boolean) => void;
}
const Logo: React.FC<LogoProps> = (props: LogoProps) => {
  const { imgWidth = "40px", imgHeight = "40px" } = props; // 기본 값 40px로 설정


  const logoClickHandler = () => {
    if (props.onClick) {
      props.onClick(false);
    }

  }

  const logoContent = useMemo(() => {
    return(
      <div className="logo">
        <ImgBox src={`${process.env.PUBLIC_URL}/images/logo.png`} width={imgWidth} height={imgHeight} />
        <span className="logo__txt ff-google-sans">License</span>
      </div>
    )}, [imgWidth, imgHeight]);

  if (props.to) {
    return (
      <Link to={`${props.to}`}
      onClick={logoClickHandler}>
        {logoContent}
      </Link>
    );
  } else {
    return logoContent;
  }
};

export default Logo;