// import React, {useCallback, useEffect, useState} from 'react';
// import "./NavGroup.css";
// import Icon from "./Icon";
// import {ICON_TYPE} from "./TYPE";
//
// interface NavGroupProps {
//   iconType?: ICON_TYPE;       // NavGroup ICON 타입 지정(TEXT)
//   iconSize?: string;          // 아이콘 사이즈 지정
//   value?: string;             // NavGroup 텍스트
//   fold?: boolean;             // Nav 접기 여부
//   children?: React.ReactNode; // TextBox 바디 컴포넌트
//   depth?: number;           // NavGroup depth 여부
//
//   isExpanded?: boolean;       // NavGroup 확장 여부
//   expandedChild?: string | null;  // 확장된 NavGroup의 value
//   onClick?: () => void;        // NavGroup 클릭 핸들러
//   onChildClick?: (value: string | null) => void; // 자식 클릭 핸들러 추가
// }
//
// function NavGroup(props: NavGroupProps) {
//   const [customIconStyle] = useState<React.CSSProperties>({
//     width: props.iconSize ?? '20px',
//     height: props.iconSize ?? '20px',
//   })
//   const calculatedPaddingLeft = `${10 + 20 * (props.depth || 0)}px`;
//
//   // NavGroup 확장 상태를 확인하기 위한 상태 추가
//   const [isNavGroupExpanded, setIsNavGroupExpanded] = useState( props.isExpanded || false );
//   const {depth, value, onChildClick} = props
//
//   useEffect(() => {
//     if(depth){
//       setIsNavGroupExpanded(props.isExpanded || false);
//     }
//   }, [depth, props.isExpanded, value]);
//
//   // nav-group__list를 클릭했을 때 확장 상태를 토글하는 핸들러 함수 추가
//   const onNavListClickHandler = useCallback((e:React.MouseEvent) => {
//     e.stopPropagation();
//     if (depth) {
//       setIsNavGroupExpanded((isExpanded) => !isExpanded);
//       onChildClick?.(value ?? null);
//     } else {
//       props.onClick?.();
//     }
//   }, [depth, props, onChildClick, value]);
//
//
//   useEffect(() => {
//     if(props.expandedChild === props.value) {
//       setIsNavGroupExpanded(true);  // 확장된 NavGroup의 value를 설정하는 함수
//     }
//     else{
//       setIsNavGroupExpanded(false);
//     }
//   }, [props.expandedChild, props.value, props.depth]);
//
//   return (
//     <>
//       <div className={`nav-group ${isNavGroupExpanded ? "selected" : ""}`} onClick={onNavListClickHandler}>
//         <div className={`nav-group__box ${isNavGroupExpanded ? "selected" : ""}`} style={{paddingLeft: calculatedPaddingLeft}}>
//           <div className="nav-group__iconbox" style={customIconStyle}>
//             {props.iconType &&
//                 <Icon className="nav-group__icon" iconType={props.iconType} size={props.iconSize}></Icon>
//             }
//           </div>
//           {!props.fold && <div className="nav-group__contents-box">{props.value}</div>}
//
//           {!props.fold && <Icon className={`nav-group__more-info ${isNavGroupExpanded ? 'expanded' : ''}`}
//                                 iconType={ICON_TYPE.EXPAND_MORE}
//                                 size="20px"/>}
//         </div>
//       </div>
//       {
//         isNavGroupExpanded && !props.fold && <div className='nav-group__inner'>
//           {props.children}
//           </div>
//       }
//     </>
//   )
// }
//
// export default NavGroup;



import React, {useCallback, useEffect, useState} from 'react';
import "./NavGroup.css";
import Icon from "./Icon";
import {ICON_TYPE} from "./TYPE";

interface NavGroupProps {
  iconType?: ICON_TYPE;       // NavGroup ICON 타입 지정(TEXT)
  iconSize?: string;          // 아이콘 사이즈 지정
  value?: string;             // NavGroup 텍스트
  fold?: boolean;             // Nav 접기 여부
  children?: React.ReactNode; // TextBox 바디 컴포넌트
  depth?: number;           // NavGroup depth 여부

  isExpanded?: boolean;       // NavGroup 확장 여부
  expandedChild?: string | null;  // 확장된 NavGroup의 value
  onClick?: () => void;        // NavGroup 클릭 핸들러
  onChildClick?: (value: string | null) => void; // 자식 클릭 핸들러 추가
}

function NavGroup(props: NavGroupProps) {
  const [customIconStyle] = useState<React.CSSProperties>({
    width: props.iconSize ?? '20px',
    height: props.iconSize ?? '20px',
  })
  const calculatedPaddingLeft = `${10 + 20 * (props.depth || 0)}px`;

  // NavGroup 확장 상태를 확인하기 위한 상태 추가
  const [isNavGroupExpanded, setIsNavGroupExpanded] = useState( props.isExpanded || false );
  const {depth, value, onChildClick} = props

  useEffect(() => {
    if(depth){
      setIsNavGroupExpanded(props.isExpanded || false);
    }
  }, [depth, props.isExpanded, value]);

  // nav-group__list를 클릭했을 때 확장 상태를 토글하는 핸들러 함수 추가
  const onNavListClickHandler = useCallback((e:React.MouseEvent) => {
    e.stopPropagation();
    if (depth) {
      setIsNavGroupExpanded((isExpanded) => !isExpanded);
      onChildClick?.(value ?? null);
    } else {
      props.onClick?.();
    }
  }, [depth, props, onChildClick, value]);


  useEffect(() => {
    if(props.expandedChild === props.value) {
      setIsNavGroupExpanded(true);  // 확장된 NavGroup의 value를 설정하는 함수
    }
    else{
      setIsNavGroupExpanded(false);
    }
  }, [props.expandedChild, props.value, props.depth]);

  return (
    <>
      <div className={`nav-group ${isNavGroupExpanded ? "selected" : ""}`} onClick={onNavListClickHandler}>
        <div className={`nav-group__box ${isNavGroupExpanded ? "selected" : ""}`} style={{paddingLeft: calculatedPaddingLeft}}>
          <div className="nav-group__iconbox" style={customIconStyle}>
            {props.iconType &&
                <Icon className="nav-group__icon" iconType={props.iconType} size={props.iconSize}></Icon>
            }
          </div>
          {!props.fold && <div className="nav-group__contents-box">{props.value}</div>}

          {!props.fold && <Icon className={`nav-group__more-info ${isNavGroupExpanded ? 'expanded' : ''}`}
                                iconType={ICON_TYPE.EXPAND_MORE}
                                size="20px"/>}
        </div>
      </div>
      {
        isNavGroupExpanded && !props.fold && <div className='nav-group__inner'>
          {props.children}
          </div>
      }
    </>
  )
}

export default NavGroup;